
import { defineComponent, ref, computed, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import WarehouseList from './components/WarehouseList.vue'
import Create from './components/Create.vue'
import { useRouter } from 'vue-router'
import WarehouseDataService from '@/services/warehouseDataService'
import { IWarehouse } from '@/interfaces/warehouse'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    sectionLayoutContent,
    FilterTable,
    WarehouseList,
    Create,
  },
  setup() {
    const state = WarehouseDataService.state
    const router = useRouter()
    const breadcrumbs = ['Warehouse', '']
    const { error, success } = useNotification()

    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      return error("Can't find user info")
    }
    const titleCreate =
      userInfo && userInfo?.userType === 'agency' ? 'Create Warehouse' : ''

    const getWarehouses = async (): Promise<void> => {
      const warehouses = await WarehouseDataService.getAll(state.params)
      state.warehouses = warehouses.data as IWarehouse[]
      state.isLoading = false
    }
    onMounted(() => {
      state.isLoading = true
      getWarehouses()
    })
    const content = computed(() => {
      return { breadcrumbs, titleCreate }
    })
    const createDialog = ref<InstanceType<typeof Create>>()

    const openCreateModal = (): void => {
      state.isSubmited = true
      createDialog.value?.open()
    }
    return {
      content,
      router,
      state,
      openCreateModal,
      createDialog,
      getWarehouses,
    }
  },
})
