import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_edit = _resolveComponent("edit")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_Create = _resolveComponent("Create")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_el_table, {
      data: _ctx.state.warehouses,
      lazy: _ctx.state.isLoading,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "created",
          label: "Created"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.moment(scope.row.createdDate).format('YYYY-MM-DD HH:mm')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "name",
          label: "Name"
        }, {
          default: _withCtx((scope) => [
            (_ctx.userInfo.userType === 'seller')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_el_button, {
                    size: "small",
                    type: "text",
                    style: {"text-decoration":"underline"},
                    onClick: ($event: any) => (
              _ctx.router.push({
                name: 'seller.warehouses.Detail',
                params: { id: scope.row._id },
              })
              )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.row.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(scope.row.name), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "code",
          label: "Code"
        }),
        _createVNode(_component_el_table_column, {
          prop: "stockType",
          label: "Stock Type"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.state.stocksType.find(v => (v.id = scope.row.stockType))?.name), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "address",
          label: "Address"
        }),
        (_ctx.userInfo.userType === 'agency')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              fixed: "right",
              label: "Operations"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_button, {
                  size: "small",
                  onClick: ($event: any) => (_ctx.editWarehouse(scope.row._id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_edit)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_el_button, {
                  size: "small",
                  type: "danger",
                  onClick: ($event: any) => (_ctx.deleteWarehouse(scope.row._id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_delete)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data", "lazy"]), [
      [_directive_loading, _ctx.state.isLoading]
    ]),
    _createVNode(_component_Create, {
      ref: "createDialog",
      onReload: _ctx.reload
    }, null, 8, ["onReload"])
  ]))
}