import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_table = _resolveComponent("filter-table")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_warehouse_list = _resolveComponent("warehouse-list")!
  const _component_Create = _resolveComponent("Create")!
  const _component_section_layout_content = _resolveComponent("section-layout-content")!

  return (_openBlock(), _createBlock(_component_section_layout_content, _mergeProps(_ctx.content, { onActionCreate: _ctx.openCreateModal }), {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_filter_table)
        ]),
        _: 1
      }),
      _createVNode(_component_warehouse_list, { onReload: _ctx.getWarehouses }, null, 8, ["onReload"]),
      _createVNode(_component_Create, {
        ref: "createDialog",
        onReload: _ctx.getWarehouses
      }, null, 8, ["onReload"])
    ]),
    _: 1
  }, 16, ["onActionCreate"]))
}