
import { Edit, Delete } from '@element-plus/icons'
import moment from 'moment'
import WarehouseDataService from '@/services/warehouseDataService'
import { ElMessageBox } from 'element-plus'
import { useNotification } from '@/composables'
import Create from './Create.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { IWarehouse, IStocksType } from '@/interfaces/warehouse'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'WarehouseList',
  components: { Edit, Delete, Create },
  setup(props, context) {
    const router = useRouter()

    const { error, success } = useNotification()
    const state = WarehouseDataService.state
    const createDialog = ref<InstanceType<typeof Create>>()
    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      return error("Can't find user info")
    }
    const deleteWarehouse = async (id: string | string[]): Promise<void> => {
      ElMessageBox.confirm(`Are you sure to delete this Warehouse?`)
        .then(async () => {
          const { data, status } = await WarehouseDataService.deleteById(id)
          if (status === 200) {
            success('Deleted')
            context.emit('reload')
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          error(err.message)
        })
    }
    const getStockType = async (): Promise<void> => {
      const stocksType = await WarehouseDataService.getStockType({})
      state.stocksType = stocksType.data
    }
    onMounted(() => {
      getStockType()
    })
    const editWarehouse = async (id: string): Promise<void> => {
      state.isSubmited = false
      state.warehouse = state.warehouses.find(
        (v: IWarehouse) => v._id === id
      ) as IWarehouse
      createDialog.value?.open()
    }
    return {
      moment,
      deleteWarehouse,
      editWarehouse,
      createDialog,
      state,
      router,
      userInfo,
    }
  },
})
