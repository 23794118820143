
import { defineComponent, ref } from 'vue'
import WarehouseDataService from '@/services/warehouseDataService'
import { useNotification } from '@/composables'
import { stringRules } from '@/utils/formRules'

export default defineComponent({
  name: 'Create',
  props: {},
  components: {},
  setup(props, context) {
    const { error, success } = useNotification()
    const state = WarehouseDataService.state
    const formAddWarehouse = ref()
    state.isContentShown = false

    const onSubmit = (): void => {
      formAddWarehouse.value.validate(async (valid: boolean): Promise<void> => {
        if (valid) {
          state.isLoading = true
          const { data, status } = state.isSubmited
            ? await WarehouseDataService.create(state.warehouse)
            : await WarehouseDataService.update(state.warehouse)
          if (status === 201 || status === 200) {
            //dialogVisible.value = false
            state.isContentShown = false
            context.emit('reload')
            success(data.message)
          } else {
            error(data)
          }
          state.isLoading = false
        }
      })
    }
    const formRules = {
      name: stringRules('name', 3, 128),
      code: stringRules('code', 3, 128),
    }
    const open = (): void => {
      state.isContentShown = true
    }
    const cancel = (): void => {
      state.isContentShown = false
    }

    return {
      state,
      cancel,
      open,
      formAddWarehouse,
      onSubmit,
      formRules,
    }
  },
})
